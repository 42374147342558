.cta{
  display: flex;
  justify-content: flex-end;
    /* background: red; */
    align-items: center;
}

.heading{
  /* background-color: #ff0000; */
  font-size: 1em;
  font-weight: 600;
  padding:15px 0;
  /* color:#fff; */
  /* margin-top: 15px; */
}
label{
  margin-bottom: 3px;
  margin-top: 22px;
}

.branding{
  display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: auto;
    align-items: center;
    padding: 2px 10px;
    /* border: 1px solid #ccc; */
    background: #e3e3e3;
  
}
.logo img{
  max-width: 150px;
}
.heading h2{
  padding-top: 5px;
  font-weight: 900;
}

.mainholder{
  margin: auto;
  max-width: 800px;
  box-shadow: 0px 0px 3px 2px #ccccccb5;
}
.inputform{
  max-width:400px;
  margin:auto;
  padding: 10px 25px 0 15px;
}
#housesvg{
  display:none;
}
#getStarted{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#Housepriceerror{
  display:none;
  justify-content: center;
  flex-direction: column;
}
#getStarted span, #Housepriceerror span{
  background: #eac1c1;
    color: red;
    padding: 5px 10px;
    border-radius: 3px;
    line-height: 11px;
    font-size: 12px;
}

#legends{
  display:none;
}
.FirstRow, .SecondRow{
  display: flex;
    justify-content: space-around;
    align-items: center;
}

.FirstRow p, .SecondRow p {
  font-size: 12px;
}
.FirstRow .legendrect{
  margin-left: 5px;
  width: 12px;
  background: #04b3fe;
  height: 9px;
  margin-right: 5px;
}

.SecondRow .legendrect{
  margin-left: 5px;
  width: 12px;
  background: #03c834;
  height: 9px;
  margin-right: 5px;
}


h3{
      text-align: left;
    margin-bottom: 0;
    font-weight: 200;
    font-size: 16px;
}
.calculatorholder{
display:flex;
justify-content: center;
max-width: 800px;
    margin: auto;

}
.leftalign{
  text-align: left;
}
.rightholder{

      background: #f1f1f1;
}

.resultlist{
  display:flex;
  justify-content: space-between;
  margin: 5px 15px;
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.resultlist:last-of-type{
  border-bottom: 0px;
}





.calculatorholder .leftholder{
  flex: 0 0 50%;
  background: #fbfbfb;
}
.calculatorholder .rightholder{
  min-width: 400PX;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Payments{
  display: flex;
  background: #e3e3e3;
  justify-content: space-between;
  margin-bottom: 20px;
  /* flex-direction: column; */
  padding: 15px 30px;
}
.recurrence p{
  font-size: 12px;
}
.recurrence {
  position: relative;
}

.recurrence span{
  font-size:30px;
}
.disclaimer{
  font-size: 9px;
    padding: 10px;
    text-align: left;
    line-height: 1;
}

.ctagroup button{
  padding: 0 8px 0 0;
}

.ConfirmationMessage{
  color: green;
    background: #a1dba1;
    text-align: center;
    /* border: 1px solid green;
    padding: 2px; */
    border-radius: 5px;
   
    font-size: 14px;
}

@media (max-width: 800px) {
  .calculatorholder {
    flex-direction: column;
    padding: 0px 10px;
    padding-bottom: 30px;
  }

  #legends{
    flex-direction: column;
  }
  .inputform {
    max-width: initial;
  }
  .calculatorholder .rightholder{
    margin-top:50px
  }
  .App-header {
    display:none;
  }
  .Payments{
    flex-direction: column;
    align-items: center;
  }
  .calculatorholder .rightholder{
      min-width:auto;
  }
}

@media print { 

  .leftholder, .cta {
     display: none;
   }  
   #printable-area {
     display: block;
   }
    
   } 
